import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

interface PageProps {
  children: React.ReactNode;
}

const container: React.CSSProperties = {
  display: "flex",
  justifyContent: "center"
};

const rightPadded: React.CSSProperties = {
  paddingRight: "1rem"
};

export const Page = (props: PageProps) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="The personal website of Harry Stevenson."
        />
        <title>Harry Stevenson</title>
      </Helmet>
      <div style={container}>
        <Link to="/" style={rightPadded}>
          <h3>home</h3>
        </Link>
      </div>
      <div style={{ minHeight: 30 }} />
      {props.children}
    </>
  );
};
